.animated-svg {
  width: 100%;
  height: 400px;
  transition: all 0.3s ease;
}

.animate__floatIn {
  animation: floatIn 0.8s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.animate__textReveal {
  animation: textReveal 0.8s ease-out forwards;
}

@keyframes floatIn {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes textReveal {
  from {
    opacity: 0;
    clip-path: inset(0 100% 0 0);
  }
  to {
    opacity: 1;
    clip-path: inset(0 0 0 0);
  }
}

.main-container span
{
  font-size: 1.2em;
}

.animated-svg:hover {
  filter: drop-shadow(0 4px 15px rgba(13, 110, 253, 0.1));
}

@media (max-width: 768px) {
  .animated-svg {
    height: 280px;
  }
  
  .display-4 {
    font-size: 2rem;
  }
  
  .feature-section {
    padding: 2rem 0;
  }
}