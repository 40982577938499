body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

header
{
	height: 70px;
}

.bgcab
{
	background-color: #f0f8ff;
}

button.navbar-toggler,
button.navbar-toggler:focus
{
	border: 0;
	box-shadow: none;
}

.navbar-nav .dropdown-menu
{
	border: 1px solid rgb(20, 120, 255, 1.0);
}

.navbar-nav .dropdown-menu,
.navbar-nav .dropdown-menu a:hover
{
	background-color: #f0f8ff;
}

.navbar-nav .dropdown-menu hr
{
	border-top: 1px solid rgb(20, 120, 255, 1.0);
}
.navbar-nav .dropdown-menu a,
.navbar-nav .dropdown-menu hr,
.nav-link
{
	color: rgb(20, 120, 255, 1.0)!important;
}

.navbar-toggler-icon
{
	background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%280, 180, 255, 1.00%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e")!important;
}

.logo
{
	margin: -8px 0;
}