.animated-svg {
  width: 100%;
  height: 400px;
  stroke-dasharray: 200;
  transition: all 0.3s ease;
}

.animate__fadeIn {
  animation: fadeIn 1s ease-out;
}

.animate__textAppear {
  animation: textAppear 0.8s ease-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes textAppear {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.bg-light {
  background-color: #f0f8ff!important;
}

.main-container
{
  padding: 0;
}

.main-container span
{
  font-size: 1.2em;
}

/*.main-container {
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
}*/

.animated-svg {
  width: 100%;
  height: 400px;
  transition: transform 0.3s ease;
}

.animated-svg:hover path {
  filter: drop-shadow(0 2px 8px rgba(13, 110, 253, 0.2));
}

.animated-svg path,
.animated-svg circle {
  transition: all 0.3s ease;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.pulse-animation {
  animation: pulse 2s infinite;
}