.logo
{
	width: 250px;
}

.business-r-t
{
	display: flex;
	justify-content: center;
	align-items: center;
}

.business-r-t-i
{
	padding: 10px 0;
}

.business-r-t-i b
{
	font-size: 1.4em;
}

.business-r-t-i span
{
	font-size: 1.2em;
}

.page-footer a
{
	color: #aaa!important;
	text-decoration: none;
	cursor: pointer;
	-webkit-transition: color 0.3s ease-in-out;
	-moz-transition: color 0.3s ease-in-out;
	-ms-transition: color 0.3s ease-in-out;
	-o-transition: color 0.3s ease-in-out;
	transition: color 0.3s ease-in-out;
}

.page-footer a:hover
{
	color: rgb(20, 120, 255, 1.0) !important;
}